<template>
  <v-container fluid class="dashboard">
    <div class="d-flex justify-space-between">
      <div>
        <h4 class="m-b-5  text-2xl text-blue font-semibold">Dashboard</h4>
      </div>
      <div>
        <div class="d-flex align-center gap-x-4">
        <v-autocomplete
            v-model="searchParams.time_intervel"
            :items="timeDuration"
            class="q-autocomplete shadow-0 add-on-prepend"
            hide-details
            dense
            item-text="title"
            item-value="name"
            outlined
            placeholder="Time Period"
            style="max-width: 180px"
            @change="changeFxn"
            height="20"
        >
          <template v-slot:prepend-inner>
            <SvgIcon class="add-on-svg">
              <template v-slot:icon>
                <EventIcon />
              </template>
            </SvgIcon>
          </template>
        </v-autocomplete>
        <div v-if="flag" class="d-flex align-center gap-x-4">
          <v-menu
              v-model="menu1"
              :close-on-content-click="false"
              :nudge-right="40"
              filled
              min-width="290px"
              offset-y
              transition="scale-transition"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                  v-model="date1Formatted"
                  class="shadow-0 q-text-field"
                  dense
                  flat
                  hide-details
                  background-color="#fff"
                  outlined
                  readonly
                  style="max-width: 180px !important"
                  v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="searchParams.start_date"
                @input="menu1 = false"
            ></v-date-picker>
          </v-menu>
          <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              min-width="290px"
              offset-y
              transition="scale-transition"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                  v-model="date2Formatted"
                  class="shadow-0 q-text-field"
                  dense
                  flat
                  hide-details
                  outlined
                  background-color="#fff"
                  readonly
                  v-on="on"
                  style="max-width: 180px !important"
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="searchParams.end_date"
                @input="menu2 = false"
            ></v-date-picker>
          </v-menu>
          <v-btn
              class="mr-2 bg-blue text-white"
              height="40"
              elevation="0"
              @click="changeFxn"
          >
            Go
          </v-btn>
        </div>
        </div>
      </div>
    </div>

    <v-row class="m-b-4">
      <v-col lg="3" sm="6" md="6">
        <v-card class="rounded-lg shadow">
          <div>
            <div class="p-3 bg-blue text-white rounded-t d-flex justify-space-between align-center">
              <SvgIcon class="font-semibold" text="Bookings">
                <template v-slot:icon>
                  <BookingIcon/>
                </template>
              </SvgIcon>
              <p class="text-sm">
                Year to Date
              </p>
            </div>
            <table class="dash_tbl table-striped">
              <tbody>
                <tr
                  v-if="
                    checkWritePermission($modules.facility.management.slug) ||
                    checkWritePermission($modules.facility.schedule.slug)
                  "
                >
                  <td class="text-left">Facility Bookings</td>
                  <td class="text-right">
                    {{ Number(typeof ytd_G != "undefined" ? ytd_G.bookings : 0) | numberFormatter }}
                  </td>
                </tr>
                <tr
                  v-if="
                    checkWritePermission($modules.events.management.slug) ||
                    checkWritePermission($modules.events.schedule.slug)
                  "
                >
                  <td class="text-left">Events Bookings</td>
                  <td class="text-right">
                    {{ Number(typeof ytd_E != "undefined" ? ytd_E.bookings : 0) | numberFormatter }}
                  </td>
                </tr>
                <tr
                  v-if="
                    checkWritePermission($modules.workshops.management.slug) ||
                    checkWritePermission($modules.workshops.schedule.slug)
                  "
                >
                  <td class="text-left">Academy Bookings</td>
                  <td class="text-right">
                    {{ Number(typeof ytd_C != "undefined" ? ytd_C.bookings : 0) | numberFormatter }}
                  </td>
                </tr>
                <tr v-if="checkWritePermission($modules.memberships.management.slug) || checkWritePermission($modules.memberships.dashboard.slug)">
                  <td class="text-left">Membership Bookings</td>
                  <td class="text-right">
                    {{ Number(typeof ytd_M != "undefined" ? ytd_M.bookings : 0) | numberFormatter }}
                  </td>
                </tr>
                <tr
                  v-if="
                    checkWritePermission($modules.trainers.management.slug) ||
                    checkWritePermission($modules.trainers.dashboard.slug)
                  "
                >
                  <td class="text-left">Trainer Bookings</td>
                  <td class="text-right">
                    {{ Number(typeof ytd_T != "undefined" ? ytd_T.bookings : 0) | numberFormatter }}
                  </td>
                </tr>
                <tr
                  v-if="
                    checkWritePermission($modules.vouchers.management.slug) ||
                    checkWritePermission($modules.vouchers.issues.slug)
                  "
                >
                  <td class="text-left">Voucher Bookings</td>
                  <td class="text-right">
                    {{ Number(typeof ytd_V != "undefined" ? ytd_V.bookings : 0) | numberFormatter }}
                  </td>

                </tr>
                <tr v-if="checkWritePermission($modules.pos.management.slug) || checkWritePermission($modules.pos.dashboard.slug)">
                  <td class="text-left">Retail Bookings</td>
                  <td class="text-right">
                    {{ Number(typeof ytd_R != "undefined" ? ytd_R.bookings : 0) | numberFormatter }}
                  </td>
                </tr>
                <tr
                >
                  <td class="text-left font-bold">Total:</td>
                  <td class="text-right font-bold">
                    {{ getTotalByKey('bookings') | numberFormatter }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </v-card>
      </v-col>
      <v-col lg="3" sm="6" md="6">
        <v-card class="rounded-lg shadow">
          <div>
            <div class="p-3 bg-neon text-white rounded-t d-flex justify-space-between align-center">
              <SvgIcon class="font-semibold"  text="Sales">
                <template v-slot:icon>
                  <WalletIcon/>
                </template>
              </SvgIcon>
              <p class="text-sm">
                Year to Date
              </p>
            </div>
            <table class="dash_tbl table-striped">
              <tbody>
                <tr
                  v-if="
                    checkWritePermission($modules.facility.management.slug) ||
                    checkWritePermission($modules.facility.schedule.slug)
                  "
                >
                  <td class="text-left">Facility Sales</td>
                  <td class="text-right">
                    {{ Number(typeof ytd_G != "undefined" ? ytd_G.sales : 0) | toCurrency }}
                  </td>
                </tr>
                <tr
                  v-if="
                    checkWritePermission($modules.events.management.slug) ||
                    checkWritePermission($modules.events.schedule.slug)
                  "
                >
                  <td class="text-left">Events Sales</td>
                  <td class="text-right">
                    {{ Number(typeof ytd_E != "undefined" ? ytd_E.sales : 0) | toCurrency }}
                  </td>
                </tr>
                <tr
                  v-if="
                    checkWritePermission($modules.workshops.management.slug) ||
                    checkWritePermission($modules.workshops.schedule.slug)
                  "
                >
                  <td class="text-left">Academy Sales</td>
                  <td class="text-right">
                    {{ Number(typeof ytd_C != "undefined" ? ytd_C.sales : 0) | toCurrency }}
                  </td>
                </tr>
                <tr v-if="checkWritePermission($modules.memberships.management.slug) || checkWritePermission($modules.memberships.dashboard.slug)">
                  <td class="text-left">Membership Sales</td>
                  <td class="text-right">
                    {{ Number(typeof ytd_M != "undefined" ? ytd_M.sales : 0) | toCurrency }}
                  </td>
                </tr>
                <tr
                  v-if="
                    checkWritePermission($modules.trainers.management.slug) ||
                    checkWritePermission($modules.trainers.dashboard.slug)
                  "
                >
                  <td class="text-left">Trainer Sales</td>
                  <td class="text-right">
                    {{ Number(typeof ytd_T != "undefined" ? ytd_T.sales : 0) | toCurrency  }}
                  </td>
                </tr>
                <tr
                  v-if="
                    checkWritePermission($modules.vouchers.management.slug) ||
                    checkWritePermission($modules.vouchers.issues.slug)
                  "
                >
                  <td class="text-left">Voucher Sales</td>
                  <td class="text-right">
                    {{ Number(typeof ytd_V != "undefined" ? ytd_V.sales : 0) | toCurrency }}
                  </td>
                </tr>
                <tr v-if="checkWritePermission($modules.pos.management.slug) || checkWritePermission($modules.pos.dashboard.slug)">
                  <td class="text-left ">Retail Sales</td>
                  <td class="text-right ">
                   {{ Number(typeof ytd_R != "undefined" ? ytd_R.sales : 0) | toCurrency }}
                  </td>
                </tr>
                <tr>
                  <td class="text-left font-bold">Total:</td>
                  <td class="text-right font-bold">
                    {{ getTotalByKey('sales') | toCurrency }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </v-card>
      </v-col>
      <v-col lg="3" sm="6" md="6">
        <v-card class="rounded-lg shadow">
          <div>
            <div class="p-3 bg-blue text-white rounded-t">
              <SvgIcon class="font-semibold" text="Bookings | Advance">
                <template v-slot:icon>
                  <BookingIcon/>
                </template>
              </SvgIcon>
            </div>
            <table class="dash_tbl table-striped">
              <tbody>
                <tr
                  v-if="
                    checkWritePermission($modules.facility.management.slug) ||
                    checkWritePermission($modules.facility.schedule.slug)
                  "
                >
                  <td class="text-left">Facility Bookings</td>
                  <td class="text-right">
                    {{ Number(typeof adv_G != "undefined" ? adv_G.bookings : 0) | numberFormatter }}
                  </td>
                </tr>
                <tr
                  v-if="
                    checkWritePermission($modules.events.management.slug) ||
                    checkWritePermission($modules.events.schedule.slug)
                  "
                >
                  <td class="text-left">Events Bookings</td>
                  <td class="text-right">
                    {{ Number(typeof adv_E != "undefined" ? adv_E.bookings : 0) | numberFormatter }}
                  </td>
                </tr>
                <tr
                  v-if="
                    checkWritePermission($modules.workshops.management.slug) ||
                    checkWritePermission($modules.workshops.schedule.slug)
                  "
                >
                  <td class="text-left">Academy Bookings</td>
                  <td class="text-right">
                    {{ Number(typeof adv_C != "undefined" ? adv_C.bookings : 0) | numberFormatter }}
                  </td>
                </tr>
                <tr
                  v-if="checkWritePermission($modules.memberships.management.slug) || checkWritePermission($modules.memberships.dashboard.slug)">
                  <td class="text-left">Membership Bookings</td>
                  <td class="text-right">
                    {{ Number(typeof adv_M != "undefined" ? adv_M.bookings : 0) | numberFormatter }}
                  </td>
                </tr>
                <tr
                  v-if="
                    checkWritePermission($modules.trainers.management.slug) ||
                    checkWritePermission($modules.trainers.dashboard.slug)
                  "
                >
                  <td class="text-left">Trainer Bookings</td>
                  <td class="text-right">
                    {{ Number(typeof adv_T != "undefined" ? adv_T.bookings : 0) | numberFormatter }}
                  </td>
                </tr>
                <tr v-if="checkWritePermission($modules.vouchers.management.slug) || checkWritePermission($modules.vouchers.issues.slug)">
                  <td class="text-left">Voucher Bookings</td>
                  <td class="text-right">
                    {{ Number(typeof adv_V != "undefined" ? adv_V.bookings : 0) | numberFormatter }}
                  </td>
                </tr>
                <tr v-if="checkWritePermission($modules.pos.management.slug) || checkWritePermission($modules.pos.dashboard.slug)">
                  <td class="text-left">Retail Bookings</td>
                  <td class="text-right">
                    {{ Number(typeof adv_R != "undefined" ? adv_R.bookings : 0) | numberFormatter }}
                  </td>
                </tr>
                <tr>
                  <td class="text-left font-bold">Total:</td>
                  <td class="text-right font-bold">
                    {{ getAdvanceTotalByKey('bookings') | numberFormatter }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </v-card>
      </v-col>
      <v-col lg="3" sm="6" md="6">
        <v-card class="rounded-lg shadow">
          <div>
            <div class="p-3 bg-neon text-white rounded-t">
              <SvgIcon class="font-semibold" text="Sales | Advance">
                <template v-slot:icon>
                  <WalletIcon/>
                </template>
              </SvgIcon>
            </div>
            <table class="dash_tbl table-striped">
              <tbody>
                <tr
                  v-if="
                    checkWritePermission($modules.facility.management.slug) ||
                    checkWritePermission($modules.facility.schedule.slug)
                  "
                >
                  <td class="text-left">Facility Sales</td>
                  <td class="text-right">
                    {{ Number(typeof adv_G != "undefined" ? adv_G.sales : 0) | toCurrency }}
                  </td>
                </tr>
                <tr
                  v-if="
                    checkWritePermission($modules.events.management.slug) ||
                    checkWritePermission($modules.events.schedule.slug)
                  "
                >
                  <td class="text-left">Events Sales</td>
                  <td class="text-right">
                    {{ Number(typeof adv_E != "undefined" ? adv_E.sales : 0) | toCurrency }}
                  </td>
                </tr>
                <tr
                  v-if="
                    checkWritePermission($modules.workshops.management.slug) ||
                    checkWritePermission($modules.workshops.schedule.slug)
                  "
                >
                  <td class="text-left">Academy Sales</td>
                  <td class="text-right">
                    {{ Number(typeof adv_C != "undefined" ? adv_C.sales : 0) | toCurrency }}
                  </td>
                </tr>
                <tr v-if=" checkWritePermission($modules.memberships.management.slug) || checkWritePermission($modules.memberships.dashboard.slug)">
                  <td class="text-left">Membership Sales</td>
                  <td class="text-right">
                    {{ Number(typeof adv_M != "undefined" ? adv_M.sales : 0) | toCurrency }}
                  </td>
                </tr>
                <tr
                  v-if="
                    checkWritePermission($modules.trainers.management.slug) ||
                    checkWritePermission($modules.trainers.dashboard.slug)
                  "
                >
                  <td class="text-left">Trainer Sales</td>
                  <td class="text-right">
                    {{ Number(typeof adv_T != "undefined" ? adv_T.sales : 0) | toCurrency }}
                  </td>
                </tr>
                <tr v-if="checkWritePermission($modules.vouchers.management.slug) || checkWritePermission($modules.vouchers.issues.slug)">
                  <td class="text-left">Voucher Sales</td>
                  <td class="text-right">
                    {{ Number(typeof adv_V != "undefined" ? adv_V.sales : 0) | toCurrency }}
                  </td>
                </tr>
                <tr v-if="checkWritePermission($modules.pos.management.slug) || checkWritePermission($modules.pos.dashboard.slug)">
                  <td class="text-left">Retail Sales</td>
                  <td class="text-right">
                    {{ Number(typeof adv_R != "undefined" ? adv_R.sales : 0) | toCurrency }}
                  </td>
                </tr>
                <tr
                >
                  <td class="text-left font-bold">Total:</td>
                  <td class="text-right font-bold">
                    {{ getAdvanceTotalByKey('sales') | toCurrency }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="m-b-4 mt-4">
      <customer-chart
        :params="params"
        :showProductType="false"
        :showVenueService="false"
        :showChartHeadder="false"
        :exportPermission="checkExportPermission($modules.general.dashboard.slug)"
        stackType="all"
        ref="customerChart"
        :show-filters="false"
        :display-top-customers="true"
        :search-params="searchParams"
        :refresh-flag="refreshFlag"
      ></customer-chart>
    </v-row>
    <v-row class="m-b-4 mt-4">
      <v-col
        col="12"
        v-if="checkReadPermission($modules.events.management.slug)"
      >
        <v-card class="shadow p-x-8 p-y-6 rounded-5">
          <div class="m-b-4 d-flex justify-space-between align-center">
            <SvgIcon text="Upcoming Events" class="gap-x-2 text-base font-semibold black-text">
            <template v-slot:icon>
              <EventIcon/>
            </template>
            </SvgIcon>
           <div>
             <v-btn
                 class="md-table-head-label cursor-pointer mx-2 bg-neon text-white"
                 @click="sortColumn('participants_count')"
                 elevation="0"
             >
               <SvgIcon text="Popularity">
               <template v-slot:icon>
                 <SortIcon/>
               </template>
               </SvgIcon>
             </v-btn>
             <v-btn
                 class="md-table-head-label cursor-pointer bg-blue text-white"
                 @click="sortColumn('date')"
                 elevation="0"
             >
               <SvgIcon text="Date">
               <template v-slot:icon>
                 <SortIcon/>
               </template>
               </SvgIcon>
             </v-btn>
           </div>
          </div>
            <v-simple-table class="table">
              <template v-slot:default>
                <thead>
                <tr class="rounded-lg tr-rounded tr-neon opacity-70" >
                  <th class="text-left black-text">Event Name</th>
                  <th class="text-left black-text">Date</th>
                  <th class="text-left black-text" style="max-width: 36%">Attendance</th>
                  <th class="text-left black-text">Sales</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in events" :key="item.id" class="text-left">
                  <td>{{ item.name }}</td>
                  <td>
                    {{ item.start_date | dateformat }}
                    {{ item.start_time | timeFormat }} to
                    {{ item.end_time | timeFormat }}
                  </td>
                  <td>
                    <v-progress-linear
                        :value="((item.participants || 0)/(item.total_tickets || 1)*100)"
                        color="#42D4A8"
                        height="25"
                        class="rounded-lg text-white "
                        background-color="rgba(234, 233, 233, 1)"
                        style="width: 250px"
                    >
                      {{ item.participants || 0 }} / {{ item.total_tickets || 0 }}
                    </v-progress-linear>
                  </td>
                  <td>
                    {{
                      Number(typeof item.sales != "undefined" ? item.sales : 0)
                          | toCurrency
                    }}</td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="m-b-4 m-t-4">

    </v-row>
    <customer-model
      v-bind="userModel"
      @updated="getTopCustomers()"
      @close="userModel.userID = null"
    />
  </v-container>
</template>
<script>
// import PieChart from "../../components/Chart/PieChart.vue";
import CustomerModel from "@/views/Clients/Customer/CustomerModel";
import CustomerChart from "@/components/Chart/CustomerPieCharts";
import { EventBus } from "@/main";
import moment from "moment";
import BookingIcon from "@/assets/images/misc/booking.svg"
import WalletIcon from "@/assets/images/misc/wallet-money.svg"
import SortIcon from "@/assets/images/misc/sort-icon.svg"
import EventIcon from "@/assets/images/misc/event-icon.svg"
import SvgIcon from "@/components/Image/SvgIcon.vue";
export default {
  data: () => ({
    dialog: false,
    show: false,
    events: null,
    barData: null,
    name: "FFFFF",
    company: null,
    email: null,
    job: null,
    phone: null,
    notes: null,
    barType: "week",
    pieType: null,
    deleter: false,
    deleteIndex: null,
    ytd_G: undefined,
    ytd_E: undefined,
    ytd_C: undefined,
    ytd_M: undefined,
    ytd_T: undefined,
    ytd_V: undefined,
    ytd_R: undefined,
    adv_G: undefined,
    adv_E: undefined,
    adv_C: undefined,
    adv_M: undefined,
    adv_T: undefined,
    adv_V: undefined,
    adv_R: undefined,
    orderBy: "date",
    orderDir: "asc",
    latestCustomer: null,
    topCustomers: null,
    userModel: { userID: null, type: "details" },
    params: { venue_service_ids: [], product_ids: [], product_type_ids: [] },
    timeDuration: [
      { name: "All", title: "All" },
      { name: "day", title: "This Day" },
      { name: "week", title: "This Week" },
      { name: "month", title: "This Month" },
      { name: "year", title: "This Year" },
      { name: "custom", title: "Custom" },
    ],
    searchParams: {
      start_date: moment().startOf("year").format("YYYY-MM-DD"),
      end_date: moment().format("YYYY-MM-DD"),
    },
    menu1: false,
    menu2: false,
    flag: false,
    refreshFlag:false,
  }),
  components: {
    SvgIcon,
    CustomerChart,
    CustomerModel,
    BookingIcon,
    WalletIcon,
    SortIcon,
    EventIcon,
  },
  computed: {
    date1Formatted() {
      return moment(this.searchParams.start_date, "YYYY-MM-DD").format(
          "Do MMM YYYY"
      );
    },
    date2Formatted() {
      return moment(this.searchParams.end_date, "YYYY-MM-DD").format(
          "Do MMM YYYY"
      );
    },
    nationalities() {
      if (!this.$store.getters.getCountries.status){
        this.$store.dispatch('loadCountries')
      }
      return this.$store.getters.getCountries.data;
    },
  },
  mounted: function () {
    this.init();
    EventBus.$on("reloadDashboard", () => {
      this.init();
    });
  },
  methods: {
    changeFxn() {
      if (this.searchParams.time_intervel == "custom") {
        this.flag = true;
        this.getDashboardCount();
      } else {
        this.flag = false;
        this.getDashboardCount();
      }
      this.refreshFlag = !this.refreshFlag;
    },
    init() {
      this.getDashboardCount();
      if (this.checkReadPermission(this.$modules.events.management.slug)) {
        this.getUpcomingEvents();
      }
      if (this.checkReadPermission(this.$modules.clients.customers.slug)) {
        // this.getLatestCustomers();
        // this.getTopCustomers();
      }
      if (this.$refs.customerChart) {
        this.$refs.customerChart.getCustomerGraphData();
      }

    },
    getLatestCustomers() {
      // this.$http
      //   .get(`venues/customers?&latest&per_page=5`)
      //   .then((response) => {
      //     if (
      //       response.status == 200 &&
      //       response.data.status == true &&
      //       response.data.data
      //     ) {
      //       this.latestCustomer = response.data.data;
      //     }
      //   })
      //   .catch((error) => {
      //     this.errorChecker(error);
      //   });
    },
    getTopCustomers() {
      if (
          this.searchParams.time_intervel != "All" &&
          this.searchParams.time_intervel != null
      ) {
        if (this.searchParams.time_intervel == "custom") {
          this.flag = true;
        } else if (this.searchParams.time_intervel == "day") {
          this.searchParams.start_date = moment().format("YYYY-MM-DD");
        } else if (this.searchParams.time_intervel == "week") {
          this.searchParams.start_date = moment()
              .startOf("week")
              .format("YYYY-MM-DD");
        } else if (this.searchParams.time_intervel == "month") {
          this.searchParams.start_date = moment()
              .startOf("month")
              .format("YYYY-MM-DD");
        } else if (this.searchParams.time_intervel == "year") {
          this.searchParams.start_date = moment()
              .startOf("year")
              .format("YYYY-MM-DD");
        }
      }
      let url = '';
      url += "&start_date=" + this.searchParams.start_date;
      url += "&end_date=" + this.searchParams.end_date;

      console.log('calling outside');
      this.$http
        .get(`venues/customers/top-customers?latest&per_page=5`+url)
        .then((response) => {

          if (
            response.status == 200 &&
            response.data
          ) {
            this.topCustomers = response.data;
          }else{
            this.topCustomers = null;
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    getUpcomingEvents() {
      let url = "";
      if (this.orderDir) {
        url = `&sort_order=${this.orderDir}`;
      }
      this.$http
        .get(`venues/events?per_page=5&status=active&type=event&is_dashboard=1` + url)
        .then((res) => {
          this.events = [];
          if (res.status == 200) {
            this.events = res.data.data;
            this.$forceUpdate();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    getDashboardCount() {

      if (
          this.searchParams.time_intervel != "All" &&
          this.searchParams.time_intervel != null
      ) {
        if (this.searchParams.time_intervel == "custom") {
          this.flag = true;
        } else if (this.searchParams.time_intervel == "day") {
          this.searchParams.start_date = moment().format("YYYY-MM-DD");
        } else if (this.searchParams.time_intervel == "week") {
          this.searchParams.start_date = moment()
              .startOf("week")
              .format("YYYY-MM-DD");
        } else if (this.searchParams.time_intervel == "month") {
          this.searchParams.start_date = moment()
              .startOf("month")
              .format("YYYY-MM-DD");
        } else if (this.searchParams.time_intervel == "year") {
          this.searchParams.start_date = moment()
              .startOf("year")
              .format("YYYY-MM-DD");
        }
      }
        let url = '';
        url += "?start_date=" + this.searchParams.start_date;
        url += "&end_date=" + this.searchParams.end_date;

      this.showLoader("Loading");
      this.$http
        .get(`venues/dashboard/ytd-advance` + url)
        .then((res) => {
          if (res.status == 200) {
            let ytd = res.data.data.ytd;
            let advance = res.data.data.advance;
            this.ytd_G = ytd.find((y) => y.name == "Facility");
            this.ytd_E = ytd.find((y) => y.name == "Event");
            this.ytd_C = ytd.find((y) => y.name == "Academy");
            this.ytd_M = ytd.find((y) => y.name == "Membership");
            this.ytd_T = ytd.find((y) => y.name == "Trainer");
            this.ytd_V = ytd.find((y) => y.name == "Voucher");
            this.ytd_R = ytd.find((y) => y.name == "POS");

            this.adv_C = advance.find((y) => y.name == "Academy");
            this.adv_E = advance.find((y) => y.name == "Event");
            this.adv_G = advance.find((y) => y.name == "Facility");
            this.adv_M = advance.find((y) => y.name == "Membership");
            this.adv_T = advance.find((y) => y.name == "Trainer");
            this.adv_V = advance.find((y) => y.name == "Voucher");
            this.adv_R = advance.find((y) => y.name == "POS");
            this.hideLoader();
            this.$forceUpdate();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    sortColumn(type) {
      if (type == this.orderBy) {
        this.orderDir = this.orderDir == "asc" ? "desc" : "asc";
      } else {
        this.orderDir = "asc";
      }
      this.orderBy = type;
      this.getUpcomingEvents();
    },
    editCustomer(userId) {
      this.userModel.type = "edit";
      this.userModel.userID = parseInt(userId);
    },
    showUserModel(userId) {
      this.userModel.userID = parseInt(userId);
      this.userModel.type = "details";
    },
    viewEvent(id) {
      this.$router.push({
        name: "EventsView",
        params: { data: btoa(id) },
      });
    },
    getTotalByKey(key){
      let total = 0;
      if ( this.checkWritePermission(this.$modules.facility.management.slug) || this.checkWritePermission(this.$modules.facility.schedule.slug)){
        total +=  Number(typeof this.ytd_G != "undefined" ? this.ytd_G[key] : 0);
      }
      if ( this.checkWritePermission(this.$modules.events.management.slug) || this.checkWritePermission(this.$modules.events.schedule.slug)){
        total +=Number(typeof this.ytd_E != "undefined" ? this.ytd_E[key] : 0);
      }
      if (this.checkWritePermission(this.$modules.workshops.management.slug) || this.checkWritePermission(this.$modules.workshops.schedule.slug)){
        total += Number(typeof this.ytd_C != "undefined" ? this.ytd_C[key] : 0);
      }
      if (this.checkWritePermission(this.$modules.memberships.management.slug) || this.checkWritePermission(this.$modules.memberships.dashboard.slug)){
        total += Number(typeof this.ytd_M != "undefined" ? this.ytd_M[key] : 0);
      }
      if ( this.checkWritePermission(this.$modules.trainers.management.slug) || this.checkWritePermission(this.$modules.trainers.dashboard.slug)){
        total += Number(typeof this.ytd_T != "undefined" ? this.ytd_T[key] : 0);
      }
      if ( this.checkWritePermission(this.$modules.vouchers.management.slug) || this.checkWritePermission(this.$modules.vouchers.issues.slug)){
        total += Number(typeof this.ytd_V != "undefined" ? this.ytd_V[key] : 0);
      }
      if ( this.checkWritePermission(this.$modules.pos.management.slug) || this.checkWritePermission(this.$modules.pos.dashboard.slug)){
        total += Number(typeof this.ytd_R != "undefined" ? this.ytd_R[key] : 0);
      }
      return total;
    },
    getAdvanceTotalByKey(key){
      let total = 0;
      if ( this.checkWritePermission(this.$modules.facility.management.slug) || this.checkWritePermission(this.$modules.facility.schedule.slug)){
        total +=  Number(typeof this.adv_G != "undefined" ? this.adv_G[key] : 0);
      }
      if ( this.checkWritePermission(this.$modules.events.management.slug) || this.checkWritePermission(this.$modules.events.schedule.slug)){
        total +=Number(typeof this.adv_E != "undefined" ? this.adv_E[key] : 0);
      }
      if (this.checkWritePermission(this.$modules.workshops.management.slug) || this.checkWritePermission(this.$modules.workshops.schedule.slug)){
        total += Number(typeof this.adv_C != "undefined" ? this.adv_C[key] : 0);
      }
      if (this.checkWritePermission(this.$modules.memberships.management.slug) || this.checkWritePermission(this.$modules.memberships.dashboard.slug)){
        total += Number(typeof this.adv_M != "undefined" ? this.adv_M[key] : 0);
      }
      if ( this.checkWritePermission(this.$modules.trainers.management.slug) || this.checkWritePermission(this.$modules.trainers.dashboard.slug)){
        total += Number(typeof this.adv_T != "undefined" ? this.adv_T[key] : 0);
      }
      if ( this.checkWritePermission(this.$modules.vouchers.management.slug) || this.checkWritePermission(this.$modules.vouchers.issues.slug)){
        total += Number(typeof this.adv_V != "undefined" ? this.adv_V[key] : 0);
      }
      if ( this.checkWritePermission(this.$modules.pos.management.slug) || this.checkWritePermission(this.$modules.pos.dashboard.slug)){
        total += Number(typeof this.adv_V != "undefined" ? this.adv_V[key] : 0);
      }
      return total;
    },
  },
};
</script>
<style scoped>
/* .headline {
  background: none;
} */
.table th {
  font-size: 1rem !important;
  height: 48px;
  border-bottom: 0 !important;
  font-weight: 500 !important;
  opacity: 1 !important;
}
.table td {
  font-size: 1rem !important;
  height: 48px;
  padding: 0 16px;
  font-weight: 400;
  border-bottom: thin solid rgba(0, 0, 0, 0.05) !important;
  opacity: 1 !important;
}
.dash_tbl {
  border-collapse: collapse;
  width: 90%;
  margin: 0 auto;
  font-weight: 400;
  font-size: 14px;
  td
  {
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.dash_tbl tr {
  //border-bottom: 1px solid rgba(255, 255, 255, 0.315);
}



</style>

<style>
.add-on-prepend .v-input__prepend-inner {
  margin-top:5px !important;
}
</style>